<template>
    <div>
        <van-nav-bar :style="{'background-color': $store.state.theme}" @click-left="goBack">
            <template #title>
                <div :style="{color: $store.state.theme == 'white' ? 'red' : 'white'}">{{title}}</div>
            </template>
            <template #right>
                <van-icon v-if="!hideFunc" :color="$store.state.theme == 'white' ? 'red' : 'white'" name="wap-nav" size="24px" @click="showPopup"/>
            </template>
            <template #left>
                <div v-if="!hideBack" style="display: flex;justify-content: center;align-items: center;">
                    <van-icon :color="$store.state.theme == 'white' ? 'red' : 'white'" name="arrow-left"/>
                    <div :style="{color: $store.state.theme == 'white' ? 'red' : 'white'}" style="margin-top: 1px">返回</div>
                </div>
            </template>
        </van-nav-bar>
        <van-popup :close-on-click-overlay="false" @click-overlay="hidePopup" v-model="show" position="right" :style="{ height: '100%', width: '50%' }">
            <div class="func-title">功能列表</div>
            <div class="funcs">
                <van-button class="fun-btn" square block type="default" @click="$router.replace({ name: 'main' })">
                    <div class="fun-content">
                        <img class="fun-item-img" src="../../statics/images/main.png">
                        <div class="fun-item-txt">主页</div>
                    </div>
                </van-button>
                <van-button class="fun-btn" square block type="default" @click="$router.replace({ name: 'bdxz' })" v-if="customer.lgs_CardType1 == 2 || customer.isStudentCard == 1">
                    <div class="fun-content">
                        <img class="fun-item-img" src="../../statics/images/wybm.png">
                        <div class="fun-item-txt">我要报名</div>
                    </div>
                </van-button>
                <van-button class="fun-btn" square block type="default"  @click="$router.replace({ name: 'qrkc' })" v-if="customer.lgs_CardType1 == 2 || customer.isStudentCard == 1">
                    <div class="fun-content">
                        <img class="fun-item-img" src="../../statics/images/wyjf.png">
                        <div class="fun-item-txt">我要缴费</div>
                    </div>
                </van-button>
                <van-button class="fun-btn" square block type="default" @click="$router.replace({ name: 'ybdbj' })" v-if="customer.lgs_CardType1 == 2 || customer.isStudentCard == 1">
                    <div class="fun-content">
                        <img class="fun-item-img" src="../../statics/images/ybmbj.png">
                        <div class="fun-item-txt">已报读班级</div>
                    </div>
                </van-button>
                <van-button class="fun-btn" square block type="default" @click="$router.replace({ name: 'zlxg' })" v-if="customer.lgs_CardType1 == 2">
                    <div class="fun-content">
                        <img class="fun-item-img" src="../../statics/images/zlxg.png">
                        <div class="fun-item-txt">资料修改</div>
                    </div>
                </van-button>
                <van-button class="fun-btn" square block type="default" @click="$router.replace({ name: 'jszlxg' })" v-if="customer.lgs_CardType1 == 8">
                  <div class="fun-content">
                    <img class="fun-item-img" src="../../statics/images/zlxg.png">
                    <div class="fun-item-txt">资料修改</div>
                  </div>
                </van-button>
                <van-button class="fun-btn" square block type="default" @click="$router.replace({ name: 'sczp' })" v-if="customer.lgs_CardType1 == 2 || customer.isStudentCard == 1">
                    <div class="fun-content">
                        <img class="fun-item-img" src="../../statics/images/sczp.png">
                        <div class="fun-item-txt">上传照片</div>
                    </div>
                </van-button>
                <van-button class="fun-btn" square block type="default" @click="$router.replace({ name: 'tkcx' })" v-if="customer.lgs_CardType1 == 2 || customer.isStudentCard == 1">
                    <div class="fun-content">
                        <img class="fun-item-img" src="../../statics/images/tkcx.png">
                        <div class="fun-item-txt">退款查询</div>
                    </div>
                </van-button>

                <!--<van-button class="fun-btn" square block type="default" @click="$router.replace({ name: 'hdgl' })" v-if="customer.lgs_CardType1 == 2">
                    <div class="fun-content">
                        <img class="fun-item-img" src="../../statics/images/tkcx.png">
                        <div class="fun-item-txt">活动管理</div>
                    </div>
                </van-button>-->
<!--              <van-button class="fun-btn" square block type="default" @click="$router.replace({ name: 'rkjh' })" v-if="customer.lgs_CardType1 == 8">
                <div class="fun-content">
                  <img class="fun-item-img" src="../../statics/images/rkjh.png">
                  <div class="fun-item-txt">教师任课计划</div>
                </div>
              </van-button>-->
              <van-button class="fun-btn" square block type="default" @click="$router.replace({ name: 'jxjh' })" v-if="customer.lgs_CardType1 == 8">
                <div class="fun-content">
                    <img class="fun-item-img" src="../../statics/images/jxjh.png">
                    <div class="fun-item-txt">教学计划填写</div>
                </div>
              </van-button>
<!--              <van-button class="fun-btn" square block type="default" @click="$router.replace({ name: 'bmqk' })" v-if="customer.lgs_CardType1 == 8 && customer.canCheckRegClass == 1">
                <div class="fun-content">
                  <img class="fun-item-img" src="../../statics/images/bmqk.png">
                  <div class="fun-item-txt">班级报名情况</div>
                </div>
              </van-button>-->
              <van-button class="fun-btn" square block type="default" @click="$router.replace({ name: 'qjsq' })" v-if="customer.lgs_CardType1 == 8">
                  <div class="fun-content">
                      <img class="fun-item-img" src="../../statics/images/qjsq.png">
                      <div class="fun-item-txt">请假申请</div>
                  </div>
              </van-button>
            </div>
        </van-popup>
    </div>
</template>
<script>
    import {NavBar, Popup, Icon, Button} from 'vant';
    import Tools from "../api/Tools";
    export default {
        props: ['title', 'show', 'hideBack', 'hideFunc', 'backFun', 'backCount'],
        components: {
            VanNavBar: NavBar,
            VanPopup: Popup,
            VanIcon: Icon,
            VanButton: Button
        },
        data() {
            return {
                customer: Tools.getCurCust()
            }
        },
        methods: {
            checkHasBlankField() {
              var cust = Tools.getCurCust()
              if (cust.imgUrl == null || cust.imgUrl == '') {
                return 1
              }
              if ((cust.fullName == null || cust.fullName == '') ||
                  (cust.urgencyTelNo == null || cust.urgencyTelNo == '') ||
                  (cust.urgencyMan == null || cust.urgencyMan == '') ||
                  (cust.healthStatus == null || cust.healthStatus == '')) {
                return 1
              }
              if (cust.age >= 18 && (cust.oriDuty == null || cust.oriDuty == '')) {
                return 1
              }
              return 0
            },
            showPopup() {
                this.$emit('showPopup');
            },
            hidePopup() {
                console.log('hidePopup')
                this.$emit('hidePopup');
            },
            goBack() {
                if (this.backCount != null) {
                    this.$router.go(-1 * this.backCount)
                } else {
                    this.$router.go(-1)
                }
            }
        }
    }
</script>
<style scoped>
.funcs {
    width: 90%;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
    .fun-btn {
        margin-top: 8px;

    }
    .fun-item-img {
        width: 20px;
    }
    .fun-content {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .fun-item-txt {
        width: 120px;
    }
    .func-title {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        border-bottom: 1px solid #dddddd;
        margin-bottom: 10px;
    }
</style>
